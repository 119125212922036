/* Home Page */
pre {
    font-family: owners, Roboto, sans-serif;
}

.header {
    background-image: url("../assets/header-image-mobile.webp");
    background-size: 100vw;
    background-repeat: no-repeat;
    height: 100vh;
}

.reportSection {
    display: flex;
    justify-content: center;
    padding: 4rem 1rem 8rem 1rem;
}
.reportImg {
    max-height: 500px;
}

.colSection {
    padding: 5rem;
    position: relative;
}

.colBox {
    border-bottom: 2px solid #8da2a9;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
}

footer {
    padding: 2rem 5rem;
    background-color: #1b4748;
    margin-top: 3rem;
}

#drawerHomeIcon {
    margin: 0 0.5em 0.2em 0;
}

@media (min-width: 780px) {
    .header {
        background-image: url("../assets/header-image-2.webp");
        height: 120vh;
    }
}
